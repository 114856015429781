export const products = [
    {
      name: "Astrofatida",
      image: `${process.env.PUBLIC_URL}/content/astrofatida1.png`,
      price: "45€",
      id: 'price_1PvMsRRthG6u34GZBl1QV1jI',
      sizes: ['XS','S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "Davide",
      image: `${process.env.PUBLIC_URL}/content/davide1.png`,
      price: "45€",
      id: 'price_1Q3i4VRthG6u34GZb64WUKE7',
      sizes: ['XS','S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "Sweatshirt",
      image: `${process.env.PUBLIC_URL}/content/skeleton1.png`,
      price: "66€",
      id: 'price_1PxvU9RthG6u34GZovOunlyo',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "Pasta",
      image: `${process.env.PUBLIC_URL}/content/m1.png`,
      price: "45€",
      id: 'price_1PvMtVRthG6u34GZ92Vb7AiB',
      sizes: ['XS','S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "Ribs",
      image: `${process.env.PUBLIC_URL}/content/r1.png`,
      price: "45€",
      id: 'price_1PvMthRthG6u34GZTlvwaBF0',
      sizes: ['XS','S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "Shorts",
      image: `${process.env.PUBLIC_URL}/content/shorts1.png`,
      price: "40€",
      id: 'price_1PvMtzRthG6u34GZZkH4AMLL',
      sizes: ['M', 'L', 'XL']
    },
    {
      name: "Hoodie",
      image: `${process.env.PUBLIC_URL}/content/su1.png`,
      price: "54€",
      id: 'price_1PvMuJRthG6u34GZqExkiT4h',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "RedFlag",
      image: `${process.env.PUBLIC_URL}/content/redflag1.png`,
      price: "52€",
      id: 'price_1Q3hvPRthG6u34GZcINK1Wa0',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "Goat",
      image: `${process.env.PUBLIC_URL}/content/goat1.png`,
      price: "58€",
      id: 'price_1Q3hvuRthG6u34GZd6jNH3AS',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "Shtrupopskii",
      image: `${process.env.PUBLIC_URL}/content/shtrupopskii1.png`,
      price: "31€",
      id: 'price_1Q3hwQRthG6u34GZ7uzTd2li',
      sizes: ['S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "Ale",
      image: `${process.env.PUBLIC_URL}/content/ale1.png`,
      price: "45€",
      id: 'price_1PwmlCRthG6u34GZSvlunU6x',
      sizes: ['XS','S', 'M', 'L', 'XL', '2XL']
    },
    {
      name: "AstroHoodie",
      image: `${process.env.PUBLIC_URL}/content/astrohoodie1.png`,
      price: "74€",
      id: 'price_1PxVX6RthG6u34GZpEXuY4pv',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "Pavia",
      image: `${process.env.PUBLIC_URL}/content/pavia1.png`,
      price: "68€",
      id: 'price_1QEvkiRthG6u34GZAAKeWfLF',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "TheBlind",
      image: `${process.env.PUBLIC_URL}/content/blb1.jpg`,
      price: "66€",
      id: 'price_1QEvmXRthG6u34GZWDvvqBPb',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "MadeinVchera",
      image: `${process.env.PUBLIC_URL}/content/miv1.jpg`,
      price: "68€",
      id: 'price_1QEvmzRthG6u34GZLErpowzd',
      sizes: ['S', 'M', 'L', 'XL']
    },
    {
      name: "Vest",
      image: `${process.env.PUBLIC_URL}/content/vest.jpg`,
      price: "€€",
      id: '',
      sizes: ['S', 'M', 'L', 'XL']
    },
    // Add more products here
  ];